import React from 'react';
import { ReactComponent as IconFacebook } from './assets/icons/facebook.svg';
import { ReactComponent as IconTwitter } from './assets/icons/twitter.svg';
import { ReactComponent as IconLinkdin } from './assets/icons/linkedin.svg';
import { ReactComponent as VeanLogo } from './assets/images/veanlogo.svg';
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo-img">
            <a href="."><VeanLogo className="logo-img" /></a>
          </div>
          <div className="social">
            <a href="https://facebook.com/veanmobile" title="Facebook" target="_blank" rel="noopener noreferrer">
              <IconFacebook className="icon" />
            </a>
            <a href="https://twitter.com/" title="Twitter" target="_blank" rel="noopener noreferrer">
              <IconTwitter className="icon" />
            </a>
            <a href="https://www.linkedin.com/veanmobile" title="LinkedIn" target="_blank" rel="noopener noreferrer">
              <IconLinkdin className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Get ready for the change.</h1>
            <p>Join the revolution and switch to more affordable, simple, reliable mobile network.  Our disruptive fully cloud-based mobile network is launching soon.</p>
            <h2>Shoot us an email or connect with us on social media if you're curious and want to join-in the revolution.</h2>
          </div>
          <a href="mailto:hello@veanmobile.com">
            <div className="cta">Send us an email</div>
          </a>
        </div>
        <div className="footer">
         <span>&copy; 2023-2026  Vean Mobile UK Ltd.</span>
        </div>
      </div>
    );
  }
}

export default App;
